import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Users, Clock, MapPin, BookOpen } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { stripeService } from "../services/stripeService";
import EventModal from "./common/EventModal";
import { eventService } from "../services/eventService";

const EventsSection = ({ className = "" }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsData = await eventService.getEvents();
        // Rellenar con eventos próximos si hay menos de 3
        const filledEvents = [...eventsData];
        if (eventsData.length < 3) {
          const placeholderEvents = [
            {
              id: "coming-soon-1",
              title: "Próximamente: Masterclass de Trading",
              description:
                "Mantente atento a nuestras próximas masterclasses donde aprenderás estrategias avanzadas de trading.",
              image_url:
                "https://static.vecteezy.com/system/resources/previews/007/516/682/non_2x/coming-soon-banner-design-with-gold-and-black-color-background-vector.jpg",
              level: "Próximamente",
              isPlaceholder: true,
            },
            {
              id: "coming-soon-2",
              title: "Próximamente: Workshop de Inversión",
              description:
                "Nuevos workshops de inversión llegarán pronto. ¡No te los pierdas!",
              image_url:
                "https://static.vecteezy.com/system/resources/previews/007/516/682/non_2x/coming-soon-banner-design-with-gold-and-black-color-background-vector.jpg",
              level: "Próximamente",
              isPlaceholder: true,
            },
          ];

          while (filledEvents.length < 3) {
            filledEvents.push(placeholderEvents[filledEvents.length - 1]);
          }
        }
        setEvents(filledEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
  }, []);

  const handleEventClick = (event) => {
    if (!event.isPlaceholder) {
      setSelectedEvent(event);
      setShowEventModal(true);
    }
  };

  const handleBuyTicket = async (event) => {
    try {
      if (!user) {
        navigate("/my-investing-journey/login");
        return;
      }

      if (event.has_access) {
        // Ya tiene acceso, mostrar mensaje de éxito
        return;
      }

      // Si el usuario tiene suscripción pero necesita upgrade
      if (
        user.user.subscription_type &&
        event.subscription_access === "advanced" &&
        user.user.subscription_type === "basic"
      ) {
        handlePortalAccess();
        return;
      }

      await stripeService.createEventCheckoutSession(event.id);
    } catch (error) {
      console.error("Error creating checkout:", error);
    }
  };

  const handlePortalAccess = async () => {
    try {
      const portalResponse = await stripeService.createCustomerPortalSession();
      window.location.href = portalResponse.url;
    } catch (error) {
      console.error("Error accessing customer portal:", error);
    }
  };

  const handleSubscribe = () => {
    navigate("/my-investing-journey/pricing");
    setShowEventModal(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      day: "numeric",
      month: "short",
    });
  };

  return (
    <section className={`py-16 ${className}`}>
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-libre text-navy mb-6 relative inline-block">
            Próximos Eventos
            <motion.div
              className="absolute -bottom-2 left-0 w-full h-1 bg-gold"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ delay: 0.5, duration: 0.8 }}
            />
          </h2>
          <p className="text-lg text-gray-600">
            No te pierdas nuestras sesiones exclusivas
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {events.map((event, index) => (
            <motion.div
              key={event.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              whileHover={!event.isPlaceholder ? { y: -8, scale: 1.02 } : {}}
              className={`group relative bg-white rounded-2xl overflow-hidden transition-all duration-300
                ${
                  !event.isPlaceholder
                    ? "cursor-pointer hover:shadow-xl"
                    : "opacity-70"
                }`}
              onClick={() => !event.isPlaceholder && handleEventClick(event)}
            >
              {event.image_url && (
                <div className="relative h-48 overflow-hidden">
                  <motion.img
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.4 }}
                    src={event.image_url}
                    alt={event.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-navy/30 to-transparent" />
                  <div className="absolute top-4 right-4 flex gap-2">
                    {event.date && (
                      <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.2 }}
                        className="bg-gold/90 backdrop-blur-sm px-4 py-2 rounded-full text-navy font-semibold shadow-lg"
                      >
                        {formatDate(event.date)}
                      </motion.div>
                    )}
                    {event.available_spots === 0 && (
                      <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3 }}
                        className="bg-red-500/90 backdrop-blur-sm text-white px-4 py-2 rounded-full text-sm font-semibold shadow-lg"
                      >
                        Agotado
                      </motion.div>
                    )}
                  </div>
                </div>
              )}

              <div className="p-8">
                <motion.h3
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="text-2xl font-libre text-navy mb-4 group-hover:text-gold transition-colors"
                >
                  {event.title}
                </motion.h3>

                <p className="text-gray-600 mb-6 line-clamp-3">
                  {event.description}
                </p>

                <div className="space-y-3 text-sm text-gray-500">
                  {event.time && (
                    <div className="flex items-center gap-2">
                      <Clock size={16} className="text-gold" />
                      <span>{event.time}</span>
                    </div>
                  )}
                  {event.location && (
                    <div className="flex items-center gap-2">
                      <MapPin size={16} className="text-gold" />
                      <span>{event.location}</span>
                    </div>
                  )}
                  {event.level && (
                    <div className="flex items-center gap-2">
                      <BookOpen size={16} className="text-gold" />
                      <span>{event.level}</span>
                    </div>
                  )}
                  {event.available_spots && (
                    <div className="flex items-center gap-2">
                      <Users size={16} className="text-gold" />
                      <span>{event.available_spots} plazas disponibles</span>
                    </div>
                  )}
                </div>

                {!event.isPlaceholder && !event.has_access && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="mt-6 pt-4 border-t border-gray-100"
                  >
                    <div className="flex justify-between items-center text-sm">
                      <span className="text-gray-500">Precio</span>
                      <span className="font-semibold text-navy">
                        {event.price / 100}€
                      </span>
                    </div>
                  </motion.div>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      <EventModal
        isOpen={showEventModal}
        onClose={() => setShowEventModal(false)}
        event={selectedEvent}
        onBuyTicket={handleBuyTicket}
        onSubscribe={handleSubscribe}
        onUpgrade={handlePortalAccess}
      />
    </section>
  );
};

export default EventsSection;
