import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Settings, LogOut, User } from "lucide-react";
import { authService } from "../services/authService";

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await authService.getCurrentUser();
        setUser(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = () => {
    authService.logout();
    navigate("/");
  };

  return (
    <nav className="bg-white shadow-md py-4 px-6">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-6">
          {user?.subscription ? (
            <Link
              to="/my-investing-journey/campus"
              className="text-navy hover:text-gold transition-colors"
            >
              Campus
            </Link>
          ) : (
            <span
              className="text-gray-400 cursor-not-allowed"
              title="Suscríbete para acceder al campus"
            >
              Campus
            </span>
          )}
          <Link
            to="/my-investing-journey/pricing"
            className="text-navy hover:text-gold transition-colors"
          >
            Pricing
          </Link>
        </div>

        <div className="relative" ref={menuRef}>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="bg-gold text-navy p-2 rounded-full hover:scale-105 active:scale-95 transition-transform"
          >
            <Settings size={20} />
          </button>

          <div
            className={`absolute right-0 mt-2 w-48 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 
            transition-opacity duration-200 ease-in-out
            ${
              isMenuOpen
                ? "opacity-100 visible"
                : "opacity-0 invisible pointer-events-none"
            }`}
          >
            <div className="py-1">
              <Link
                to="/my-investing-journey/profile"
                className="flex items-center px-4 py-2 text-sm text-navy hover:bg-cream"
                onClick={() => setIsMenuOpen(false)}
              >
                <User size={16} className="mr-2" />
                Mi Perfil
              </Link>
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-4 py-2 text-sm text-navy hover:bg-cream"
              >
                <LogOut size={16} className="mr-2" />
                Cerrar Sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
