import axios from "axios";

//const API_URL = "http://localhost:7012/api";
const API_URL = "https://investingafterworks.com/api";

export const googleService = {
  getReviews: async () => {
    try {
      const response = await axios.get(`${API_URL}/google-reviews`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Google reviews:", error);
      throw error;
    }
  },
};
