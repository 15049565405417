let isLoading = false;
let isLoaded = false;
const callbacks = [];

export const loadGoogleMapsScript = (callback) => {
  if (isLoaded) {
    callback();
    return;
  }

  callbacks.push(callback);

  if (isLoading) {
    return;
  }

  isLoading = true;

  // Crear el script con async
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAw6BkQZM9JNHTOifMNa4jOok-xJv1dmmM&libraries=places,geocoding&loading=async&callback=initGoogleMaps`;
  script.async = true;
  script.defer = true;

  window.initGoogleMaps = () => {
    isLoaded = true;
    isLoading = false;
    callbacks.forEach((cb) => cb());
    callbacks.length = 0;
  };

  // Manejar errores de carga
  script.onerror = () => {
    console.error("Error loading Google Maps script");
    isLoading = false;
    callbacks.length = 0;
  };

  document.head.appendChild(script);
};
