import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { userService } from "../services/userService";
import { stripeService } from "../services/stripeService";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import PricingPlans from "./PricingPlans";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await userService.getUserProfile();
        setUserData(data);
        setEditedData(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/my-investing-journey/login");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await userService.updateUserProfile(editedData);
      setUserData(editedData);
      setIsEditing(false);
      // Mostrar mensaje de éxito
    } catch (error) {
      console.error("Error updating profile:", error);
      // Mostrar mensaje de error
    }
  };

  const handleCancel = () => {
    setEditedData(userData);
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setEditedData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePortalAccess = async () => {
    try {
      const portalResponse = await stripeService.createCustomerPortalSession();
      window.location.href = portalResponse.url;
    } catch (error) {
      console.error("Error accessing customer portal:", error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-cream flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gold"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-cream py-12">
      <div className="max-w-7xl mx-auto px-8 sm:px-12 lg:px-16">
        {/* Botón de retorno */}
        <motion.button
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          onClick={() => navigate("/my-investing-journey/dashboard")}
          className="mb-8 flex items-center gap-2 text-navy hover:text-gold 
            transition-colors duration-200 font-inter font-medium"
        >
          <ArrowLeft size={20} />
          <span>Volver al Dashboard</span>
        </motion.button>

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-libre text-navy mb-4">
            Mi Perfil
          </h1>
          {!isEditing ? (
            <button
              onClick={handleEdit}
              className="bg-navy text-cream font-inter font-semibold 
                text-base py-2 px-6 rounded-lg hover:bg-opacity-90 
                transition-all duration-200"
            >
              Editar Perfil
            </button>
          ) : (
            <div className="space-x-4">
              <button
                onClick={handleSave}
                className="bg-gold text-navy font-inter font-semibold 
                  text-base py-2 px-6 rounded-lg hover:brightness-90 
                  transition-all duration-200"
              >
                Guardar Cambios
              </button>
              <button
                onClick={handleCancel}
                className="bg-gray-300 text-navy font-inter font-semibold 
                  text-base py-2 px-6 rounded-lg hover:bg-gray-400 
                  transition-all duration-200"
              >
                Cancelar
              </button>
            </div>
          )}
        </motion.div>

        {/* Grid de información */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Información Personal */}
          <PersonalInfoSection
            userData={isEditing ? editedData : userData}
            isEditing={isEditing}
            onChange={handleChange}
          />

          {/* Información Profesional */}
          <ProfessionalInfoSection
            userData={isEditing ? editedData : userData}
            isEditing={isEditing}
            onChange={handleChange}
          />

          {/* Estado de Suscripción (solo si tiene suscripción) */}
          {userData?.subscription_status === "active" && (
            <SubscriptionStatusSection
              userData={userData}
              onPortalAccess={handlePortalAccess}
            />
          )}
        </div>

        {/* PricingPlans (solo si no tiene suscripción activa) */}
        {userData?.subscription_status !== "active" && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.6 }}
            className="mt-12"
          >
            <PricingPlans />
          </motion.div>
        )}
      </div>
    </div>
  );
};

// Componentes auxiliares para las secciones
const PersonalInfoSection = ({ userData, isEditing, onChange }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.2 }}
      className="bg-white rounded-xl shadow-lg p-8"
    >
      <h2 className="text-2xl font-libre text-navy mb-6 relative inline-block">
        Información Personal
        <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gold"></div>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <InfoField
          label="Nombre"
          value={userData?.name || "No especificado"}
          isEditing={isEditing}
          onChange={(value) => onChange("name", value)}
        />
        <InfoField
          label="Email"
          value={userData?.email || "No especificado"}
          isEditing={isEditing}
          onChange={(value) => onChange("email", value)}
        />
        <InfoField
          label="DNI"
          value={userData?.dni || "No especificado"}
          isEditing={isEditing}
          onChange={(value) => onChange("dni", value)}
        />
        <InfoField
          label="WhatsApp"
          value={userData?.whatsapp || "No especificado"}
          isEditing={isEditing}
          onChange={(value) => onChange("whatsapp", value)}
        />
        <InfoField
          label="Dirección de Facturación"
          value={userData?.billing_address || "No especificada"}
          isEditing={isEditing}
          onChange={(value) => onChange("billing_address", value)}
        />
      </div>
    </motion.div>
  );
};

const ProfessionalInfoSection = ({ userData, isEditing, onChange }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.3 }}
      className="bg-white rounded-xl shadow-lg p-8"
    >
      <h2 className="text-2xl font-libre text-navy mb-6 relative inline-block">
        Información Profesional
        <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gold"></div>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="md:col-span-2">
          <InfoField
            value={userData?.professional_info || "No especificada"}
            isEditing={isEditing}
            onChange={(value) => onChange("professional_info", value)}
            multiline
          />
        </div>
      </div>
    </motion.div>
  );
};

const InfoField = ({
  label,
  value,
  isEditing,
  onChange,
  multiline = false,
}) => {
  if (isEditing) {
    return (
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-600 font-inter">
          {label}
        </label>
        {multiline ? (
          <textarea
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md 
              shadow-sm focus:ring-gold focus:border-gold font-inter"
            rows={4}
          />
        ) : (
          <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md 
              shadow-sm focus:ring-gold focus:border-gold font-inter"
          />
        )}
      </div>
    );
  }

  return (
    <div className="space-y-1">
      <label className="block text-sm text-gray-600 font-inter">{label}</label>
      <p className="text-lg font-medium text-navy font-inter">{value}</p>
    </div>
  );
};

const SubscriptionStatusSection = ({ userData, onPortalAccess }) => {
  const formatSubscriptionType = (type) => {
    const types = {
      basic: "Plan Básico",
      advanced: "Plan Avanzado",
      elite: "Plan Élite",
      none: "Sin plan contratado",
    };
    return types[type] || "Sin plan contratado";
  };

  const formatSubscriptionStatus = (status) => {
    const statuses = {
      active: "Activa",
      inactive: "Inactiva",
      cancelled: "Cancelada",
      none: "Sin suscripción",
    };
    return statuses[status] || "Sin suscripción";
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.4 }}
      className="lg:col-span-2 bg-white rounded-xl shadow-lg p-8"
    >
      <h2 className="text-2xl font-libre text-navy mb-6 relative inline-block">
        Estado de Suscripción
        <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-gold"></div>
      </h2>
      <div className="space-y-4">
        <InfoField
          label="Plan Actual"
          value={formatSubscriptionType(userData?.subscription_type)}
        />
        <InfoField
          label="Estado"
          value={formatSubscriptionStatus(userData?.subscription_status)}
        />
        <button
          onClick={onPortalAccess}
          className="mt-4 bg-gold text-navy font-inter font-semibold 
            text-base md:text-lg py-2 px-6 rounded-lg hover:brightness-90 
            transition-all duration-200 hover:scale-105 active:scale-95"
        >
          Gestionar Suscripción
        </button>
      </div>
    </motion.div>
  );
};

export default Profile;
