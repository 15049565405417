import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { googleService } from "../services/googleService";
import { Star } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";

const GoogleLogo = () => (
  <svg className="h-5 w-5" viewBox="0 0 48 48">
    <path
      fill="#FFC107"
      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
    />
    <path
      fill="#FF3D00"
      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
    />
    <path
      fill="#4CAF50"
      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
    />
    <path
      fill="#1976D2"
      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
    />
  </svg>
);

const VideoCarousel = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={30}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 5000, disableOnInteraction: true }}
      className="mb-16 rounded-xl overflow-hidden"
      breakpoints={{
        640: { slidesPerView: 2 },
        1024: { slidesPerView: 3 },
      }}
    >
      {/* Instagram Post 1 */}
      <SwiperSlide>
        <div className="aspect-[9/16] relative rounded-xl overflow-hidden">
          <iframe
            title="Instagram Post 1"
            src="https://www.instagram.com/p/DEsBnVoN90m/embed"
            className="absolute inset-0 w-full h-full"
            frameBorder="0"
            scrolling="no"
            allowtransparency="true"
            allow="autoplay"
          ></iframe>
        </div>
      </SwiperSlide>

      {/* LinkedIn Post */}
      <SwiperSlide>
        <div className="aspect-[9/16] relative rounded-xl overflow-hidden">
          <iframe
            src={`https://www.linkedin.com/embed/feed/update/urn:li:activity:7285343735901556738`}
            className="absolute inset-0 w-full h-full"
            frameBorder="0"
            allowFullScreen=""
            title="LinkedIn Post"
          ></iframe>
        </div>
      </SwiperSlide>

      {/* Instagram Post 2 */}
      <SwiperSlide>
        <div className="aspect-[9/16] relative rounded-xl overflow-hidden">
          <iframe
            title="Instagram Post 2"
            src="https://www.instagram.com/p/DEFQURlNito/embed"
            className="absolute inset-0 w-full h-full"
            frameBorder="0"
            scrolling="no"
            allowtransparency="true"
            allow="autoplay"
          ></iframe>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

const ReviewCard = ({ review }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
    });
  };

  return (
    <div className="bg-white rounded-xl p-6 shadow-lg h-full flex flex-col">
      <div className="flex items-start space-x-4 mb-4">
        <div className="flex-shrink-0">
          <div className="w-14 h-14 rounded-full overflow-hidden border-2 border-gray-100">
            <img
              src={review.profile_photo_url}
              alt={review.author_name}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.src =
                  "https://ui-avatars.com/api/?name=" +
                  encodeURIComponent(review.author_name);
              }}
            />
          </div>
        </div>
        <div className="flex-1">
          <h4 className="font-inter font-medium text-[#1a73e8] text-lg mb-1">
            {review.author_name}
          </h4>
          <div className="flex items-center space-x-2">
            <div className="flex">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  size={16}
                  className={
                    i < review.rating
                      ? "text-[#f1c40f] fill-[#f1c40f]"
                      : "text-gray-300"
                  }
                />
              ))}
            </div>
            <span className="text-sm text-gray-500 font-inter">
              {formatDate(review.time)}
            </span>
          </div>
        </div>
      </div>

      <div className="flex-grow">
        <p className="text-gray-700 font-inter leading-relaxed line-clamp-4">
          {review.text}
        </p>
      </div>

      <div className="pt-4 mt-4 border-t border-gray-100 flex justify-end items-center space-x-2">
        <span className="text-sm text-gray-500 font-inter">Publicado en</span>
        <GoogleLogo />
      </div>
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="min-h-[400px] flex flex-col items-center justify-center space-y-4">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gold"></div>
    <div className="text-navy font-inter text-lg">Cargando reseñas...</div>
  </div>
);

const ReviewsSection = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await authService.verifyToken();
        setIsAuthenticated(!!user);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const data = await googleService.getReviews();
        setReviews(data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const handleStartGrowing = () => {
    if (isAuthenticated) {
      navigate("/my-investing-journey/dashboard");
    } else {
      navigate("/my-investing-journey/login");
    }
  };

  if (loading) {
    return (
      <div className="py-20 bg-cream">
        <div className="max-w-7xl mx-auto px-8 sm:px-12 lg:px-16">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="py-20 bg-cream">
      <div className="max-w-7xl mx-auto px-8 sm:px-12 lg:px-16">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-libre text-navy mb-6 relative inline-block">
            ¿Qué opinan de nosotros?
            <motion.div
              className="absolute -bottom-2 left-0 w-full h-1 bg-gold"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ delay: 0.5, duration: 0.8 }}
            />
          </h2>
        </motion.div>

        <VideoCarousel />

        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            640: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          className="mb-16"
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <ReviewCard review={review} />
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Botón de acción */}
        <div className="text-center mt-12">
          <button
            onClick={handleStartGrowing}
            className="bg-gold text-navy font-inter font-semibold 
              text-base md:text-lg py-3 px-8 
              rounded-lg hover:brightness-90 transition-all
              hover:scale-105 active:scale-95"
          >
            ¡QUIERO UNIRME!
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewsSection;
