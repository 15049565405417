import React, { useState, useEffect, useRef } from "react";
import landing_background from "../assets/images/landing_background.png";
import gold_logo from "../assets/logos/gold.png";
import landing_models from "../assets/images/landing_marta_y_david.png";
import { Link, useNavigate } from "react-router-dom";
import { Settings, LogOut, User } from "lucide-react";
import { authService } from "../services/authService";

const LandingSection = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await authService.verifyToken();
        setIsAuthenticated(!!user);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  // Cerrar el menú al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = () => {
    authService.logout();
    setIsAuthenticated(false);
    setIsMenuOpen(false);
    navigate("/");
  };

  // Reemplazar el botón de usuario con este nuevo componente
  const UserMenu = () => (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className={`bg-gold text-navy p-3 rounded-full hover:scale-110 active:scale-95 transition-all`}
      >
        <Settings size={24} />
      </button>

      {/* Menú desplegable */}
      <div
        className={`absolute left-0 top-full mt-2 w-48 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 
        transition-opacity duration-200 ease-in-out
        ${
          isMenuOpen
            ? "opacity-100 visible"
            : "opacity-0 invisible pointer-events-none"
        }`}
      >
        <div className="py-1">
          <Link
            to="/my-investing-journey/profile"
            className="flex items-center px-4 py-2 text-sm text-navy hover:bg-cream font-inter"
            onClick={() => setIsMenuOpen(false)}
          >
            <User size={16} className="mr-2" />
            Mi Perfil
          </Link>
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-4 py-2 text-sm text-navy hover:bg-cream font-inter"
          >
            <LogOut size={16} className="mr-2" />
            Cerrar Sesión
          </button>
        </div>
      </div>
    </div>
  );

  const handleStartGrowing = () => {
    if (isAuthenticated) {
      navigate("/my-investing-journey/dashboard");
    } else {
      navigate("/my-investing-journey/login");
    }
  };

  return (
    <div className="relative">
      {/* Botón de acceso - Solo cambiamos el texto */}
      <div className="absolute top-4 left-4 z-50">
        {isAuthenticated ? (
          <UserMenu />
        ) : (
          <Link
            to="/my-investing-journey/login"
            className="bg-gold text-navy font-inter font-semibold 
            text-base sm:text-lg px-3 sm:px-6 py-2 sm:py-3 
            rounded-lg hover:scale-105 active:scale-95 transition-all 
            flex items-center justify-center whitespace-nowrap"
          >
            Accede
          </Link>
        )}
      </div>
      <div
        className="bg-cover bg-center min-h-screen"
        style={{ backgroundImage: `url(${landing_background})` }}
      >
        {/* Contenedor principal */}
        <div className="flex flex-col md:flex-row justify-between p-4 pb-0 md:p-8 md:pb-0 h-full">
          {/* Contenido izquierdo */}
          <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center space-y-4 md:space-y-6">
            <img
              src={gold_logo}
              alt="Logo"
              className="mb-4 md:mb-0 max-w-[150px] h-auto md:hidden mt-12"
            />

            <h1 className="text-3xl md:text-5xl font-libre text-gold leading-tight md:leading-snug">
              Toma el control de tus finanzas.{" "}
              <br className="hidden md:block" />
              Cambia tu vida.
            </h1>
            <p className="text-sm md:text-lg text-white font-inter">
              Domina los secretos de las finanzas personales y las inversiones
              de la mano de los expertos más TOP.
            </p>
            <p className="text-sm md:text-lg text-white font-inter">
              Desata todo tu potencial <br className="hidden md:block" />y haz
              que <strong>tu dinero trabaje por ti</strong>.
            </p>
            <button
              onClick={handleStartGrowing}
              className="bg-gold text-navy font-inter font-semibold text-sm md:text-base lg:text-xl py-2 px-4 lg:py-3 lg:px-6 rounded-lg hover:brightness-90 transition-all"
            >
              EMPIEZA A CRECER HOY
            </button>
          </div>

          {/* Contenido derecho */}
          <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
            <img
              src={gold_logo}
              alt="Logo"
              className="mb-4 hidden md:block max-w-sm"
            />
            <img
              src={landing_models}
              alt="Modelos"
              className="max-w-full h-auto"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
