import axios from "axios";

//const API_URL = "http://localhost:7012/api";
const API_URL = "https://investingafterworks.com/api";

export const userService = {
  getUserProfile: async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.get(`${API_URL}/users/${user.user.id}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      return {
        ...response.data,
        subscription_status: response.data.subscription_status,
        subscription_type: response.data.subscription_type,
        dni: response.data.dni,
        whatsapp: response.data.whatsapp,
        billing_address: response.data.billing_address,
        payment_status: response.data.payment_status || "none",
        professional_info: response.data.professional_info,
        created_at: response.data.created_at,
        updated_at: response.data.updated_at,
        last_payment_attempt: response.data.last_payment_attempt || null,
        payment_failure_count: response.data.payment_failure_count || 0,
      };
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  },

  updateUserProfile: async (userData) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.put(
        `${API_URL}/users/${user.user.id}`,
        userData,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating user profile:", error);
      throw error;
    }
  },
};
