import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";
import { stripeService } from "../services/stripeService";
import SubscriptionModal from "./SubscriptionModal";

const PricingPlans = () => {
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleSubscribe = async (planType) => {
    if (!currentUser) {
      localStorage.setItem("selectedPlan", planType);
      navigate("/my-investing-journey/login");
      return;
    }

    try {
      const response = await stripeService.createCheckoutSession(planType);

      if (response.error === "existing_subscription") {
        setModalMessage(response.message);
        setModalOpen(true);
        return;
      }

      window.location.href = response.url;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  const handlePortalAccess = async () => {
    try {
      const portalResponse = await stripeService.createCustomerPortalSession();
      window.location.href = portalResponse.url;
    } catch (error) {
      console.error("Error accessing customer portal:", error);
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  return (
    <>
      <div className="bg-cream py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl md:text-5xl lg:text-6xl font-libre text-navy text-center mb-4 leading-tight font-extrabold tracking-tight">
              Planes de Suscripción
            </h2>
            <p className="mt-4 text-xl text-gray-600 font-inter">
              Elige el plan que mejor se adapte a tus necesidades
            </p>
          </div>

          <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
            {/* Plan Básico */}
            <div className="border border-gray-200 rounded-lg shadow-lg divide-y divide-gray-200 bg-white transform hover:scale-105 transition-transform duration-300">
              <div className="p-6">
                <h3 className="text-2xl font-libre leading-6 font-bold text-navy">
                  Básico
                </h3>
                <p className="mt-4 text-lg text-gray-500 font-inter">
                  Ideal para comenzar tu viaje de inversión
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-navy font-libre">
                    30€
                  </span>
                  <span className="text-xl font-medium text-gray-500 font-inter">
                    /mes
                  </span>
                </p>
                <ul className="mt-6 space-y-4">
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Eventos Básico (1 al mes)
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Grupo de WhatsApp con Inversores Profesionales
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ MasterMinds Básicos
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ MasterMinds Avanzados con 50% descuento
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Acceso a My Investing Journey
                    </span>
                  </li>
                </ul>
                <button
                  onClick={() => handleSubscribe("basic")}
                  className="mt-8 block w-full py-3 px-4 border border-transparent text-lg font-inter font-semibold rounded-lg text-navy bg-gold hover:brightness-90 transition-all"
                >
                  Suscribirse
                </button>
              </div>
            </div>

            {/* Plan Avanzado (destacado) */}
            <div className="border-2 border-gold rounded-lg shadow-xl divide-y divide-gray-200 bg-white transform hover:scale-105 transition-transform duration-300">
              <div className="p-6">
                <div className="absolute top-0 right-0 -mr-1 -mt-1 w-24 h-24 overflow-hidden">
                  <div className="absolute transform rotate-45 bg-gold text-navy text-xs font-semibold py-1 right-[-35px] top-[32px] w-[170px] text-center">
                    Recomendado
                  </div>
                </div>
                <h3 className="text-2xl font-libre leading-6 font-bold text-navy">
                  Avanzado
                </h3>
                <p className="mt-4 text-lg text-gray-500 font-inter">
                  Para inversores comprometidos
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-navy font-libre">
                    50€
                  </span>
                  <span className="text-xl font-medium text-gray-500 font-inter">
                    /mes
                  </span>
                </p>
                <ul className="mt-6 space-y-4">
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Eventos Avanzados (1 al mes)
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Asesorías One 2 One
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Grupo de WhatsApp Premium
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ MasterMinds Intermedios
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Acceso a evento Élite Semestral
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Acceso a My Investing Journey
                    </span>
                  </li>
                </ul>
                <button
                  onClick={() => handleSubscribe("advanced")}
                  className="mt-8 block w-full py-3 px-4 border border-transparent text-lg font-inter font-semibold rounded-lg text-navy bg-gold hover:brightness-90 transition-all"
                >
                  Suscribirse
                </button>
              </div>
            </div>

            {/* Plan Élite */}
            <div className="border border-gray-200 rounded-lg shadow-lg divide-y divide-gray-200 bg-white transform hover:scale-105 transition-transform duration-300">
              <div className="p-6">
                <h3 className="text-2xl font-libre leading-6 font-bold text-navy">
                  Élite
                </h3>
                <p className="mt-4 text-lg text-gray-500 font-inter">
                  Exclusivo por invitación
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-navy font-libre">
                    Consultar
                  </span>
                </p>
                <ul className="mt-6 space-y-4">
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Membership exclusiva a Club Élite
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <span className="text-sm text-gray-500">
                      ✓ Solo por recomendación de miembro élite
                    </span>
                  </li>
                </ul>
                <button
                  onClick={() => handleSubscribe("elite")}
                  className="mt-8 block w-full bg-gray-300 cursor-not-allowed text-white font-medium py-2 px-4 rounded"
                  disabled
                >
                  Solo por invitación
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <SubscriptionModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handlePortalAccess}
        message={modalMessage}
      />
    </>
  );
};

export default PricingPlans;
