import axios from "axios";

//const API_URL = "http://localhost:7012/api";
const API_URL = "https://investingafterworks.com/api";

export const eventService = {
  getEvents: async () => {
    try {
      const response = await axios.get(`${API_URL}/events`);
      return response.data;
    } catch (error) {
      console.error("Error fetching events:", error);
      throw error;
    }
  },

  getUserTickets: async (eventId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.get(`${API_URL}/events/${eventId}/tickets`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching tickets:", error);
      throw error;
    }
  },
};
