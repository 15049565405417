// frontend/src/components/Register.js
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { authService } from "../services/authService";
import { motion } from "framer-motion";
import { DownloadModal } from "./common/DownloadModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { loadGoogleMapsScript } from "../utils/googleMapsLoader";

// Añade estos estilos personalizados para el input de teléfono
const phoneInputStyles = {
  container: "!w-full",
  input: "!w-full !h-[42px] !pl-[48px]",
  button: "!border-gray-300 !h-[42px]",
};

const Register = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    phone: "",
    whatsapp: "",
    dni: "",
    professional_info: "",
  });

  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "42px",
      minHeight: "42px",
      borderColor: "#E5E7EB",
      "&:hover": {
        borderColor: "#D1D5DB",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "#C5A572"
        : state.isFocused
        ? "#F3F4F6"
        : "white",
      color: state.isSelected ? "white" : "#1F2937",
    }),
  };

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value) ? "" : "Email no válido";

      case "name":
        return value.length >= 3
          ? ""
          : "El nombre debe tener al menos 3 caracteres";

      case "password":
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return passwordRegex.test(value)
          ? ""
          : "La contraseña debe tener al menos 8 caracteres, una letra y un número";

      case "confirmPassword":
        return value === formData.password
          ? ""
          : "Las contraseñas no coinciden";

      case "dni":
        const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
        const nieRegex = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
        return dniRegex.test(value) || nieRegex.test(value)
          ? ""
          : "DNI/NIE no válido";

      case "whatsapp":
        const phoneRegex = /^(?:(?:\+|00)34|0034|34)?[6789]\d{8}$/;
        return phoneRegex.test(value) ? "" : "Número de teléfono no válido";

      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const fieldError = validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      whatsapp: value,
    }));
  };

  const handleAddressSelect = async (address) => {
    try {
      // Esperar a que Google Maps esté completamente cargado
      if (!window.google || !window.google.maps) {
        console.error("Google Maps no está cargado correctamente");
        return;
      }

      // Usar Places Service en lugar de Geocoder
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      placesService.getDetails(
        {
          placeId: address.value.place_id,
          fields: ["address_components", "formatted_address"],
        },
        (place, status) => {
          if (status === "OK" && place) {
            let postalCode = "";
            let street = "";
            let number = "";
            let city = "";

            place.address_components.forEach((component) => {
              const types = component.types;
              if (types.includes("postal_code")) {
                postalCode = component.long_name;
              } else if (types.includes("route")) {
                street = component.long_name;
              } else if (types.includes("street_number")) {
                number = component.long_name;
              } else if (types.includes("locality")) {
                city = component.long_name;
              }
            });

            const fullAddress = `${street}, ${number}, ${postalCode} ${city}, España`;

            setFormData((prev) => ({
              ...prev,
              billing_address: fullAddress,
            }));

            console.log("Dirección seleccionada:", fullAddress);
          }
        }
      );
    } catch (error) {
      console.error("Error al procesar la dirección:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar todos los campos requeridos
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (key !== "industry" && key !== "additional_info") {
        const fieldError = validateField(key, formData[key]);
        if (fieldError) newErrors[key] = fieldError;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const userData = {
        ...formData,
        confirmPassword: undefined,
        billing_address: formData.billing_address,
      };

      await authService.register(userData);
      setShowDownloadModal(true);
    } catch (err) {
      setError(err.response?.data?.error || "Error en el registro");
    }
  };

  const downloadPDF = async () => {
    try {
      const response = await fetch("/files/iaw_guide.pdf");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download =
        "Guía Básica sobre Cómo Invertir Bien - Investing Afterworks";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Redirigir a pricing con el parámetro gift
      navigate("/my-investing-journey/pricing?gift=true");
    } catch (error) {
      console.error("Error al descargar el PDF:", error);
      navigate("/my-investing-journey/pricing?gift=true");
    }
  };

  const handleCloseModal = () => {
    setShowDownloadModal(false);
    navigate("/my-investing-journey/pricing?gift=true");
  };

  const addressSelectId = useMemo(() => `address-select-${Math.random()}`, []);

  useEffect(() => {
    // Cargar Google Maps solo si no está cargado
    if (!window.google) {
      loadGoogleMapsScript(() => {
        setIsGoogleLoaded(true);
      });
    } else {
      setIsGoogleLoaded(true);
    }
  }, []);

  return (
    <>
      <div className="min-h-screen bg-cream flex flex-col justify-center py-12 sm:px-6 lg:px-8 relative">
        {/* Botón de retorno */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="absolute top-4 left-4 z-50"
        >
          <Link
            to="/"
            className="bg-gold text-navy font-inter font-semibold 
            text-base sm:text-lg px-3 sm:px-6 py-2 sm:py-3 
            rounded-lg hover:scale-105 active:scale-95 transition-all 
            flex items-center justify-center whitespace-nowrap"
          >
            Volver a Inicio
          </Link>
        </motion.div>

        <div className="sm:mx-auto sm:w-full sm:max-w-2xl">
          <div className="bg-white py-8 px-4 shadow-xl sm:rounded-lg sm:px-10">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-libre font-bold text-navy">
                Crear cuenta
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                Únete a nuestra comunidad de inversores y comienza tu viaje
              </p>
            </div>

            <form className="space-y-8" onSubmit={handleSubmit}>
              {error && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  {error}
                </motion.div>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Columna de información básica */}
                <div className="space-y-6">
                  <h3 className="text-lg font-medium text-navy">
                    Información básica
                  </h3>

                  {/* Email */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                      className={`mt-1 block w-full border ${
                        errors.email ? "border-red-500" : "border-gray-300"
                      } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold`}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="mt-1 text-xs text-red-500">
                        {errors.email}
                      </p>
                    )}
                  </div>

                  {/* Nombre completo */}
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nombre completo
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="name"
                      required
                      className={`mt-1 block w-full border ${
                        errors.name ? "border-red-500" : "border-gray-300"
                      } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold`}
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p className="mt-1 text-xs text-red-500">{errors.name}</p>
                    )}
                  </div>

                  {/* Teléfono/WhatsApp */}
                  <div>
                    <label
                      htmlFor="whatsapp"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Teléfono/WhatsApp
                    </label>
                    <PhoneInput
                      country={"es"}
                      value={formData.whatsapp}
                      onChange={handlePhoneChange}
                      inputProps={{
                        required: true,
                        name: "whatsapp",
                      }}
                      containerClass={phoneInputStyles.container}
                      inputClass={phoneInputStyles.input}
                      buttonClass={phoneInputStyles.button}
                    />
                  </div>

                  {/* Contraseña */}
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Contraseña
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className={`mt-1 block w-full border ${
                        errors.password ? "border-red-500" : "border-gray-300"
                      } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold`}
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="mt-1 text-xs text-red-500">
                        {errors.password}
                      </p>
                    )}
                  </div>

                  {/* Confirmar contraseña */}
                  <div>
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirmar contraseña
                    </label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      autoComplete="new-password"
                      required
                      className={`mt-1 block w-full border ${
                        errors.confirmPassword
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold`}
                      value={formData.confirmPassword}
                      onChange={handleChange}
                    />
                    {errors.confirmPassword && (
                      <p className="mt-1 text-xs text-red-500">
                        {errors.confirmPassword}
                      </p>
                    )}
                  </div>
                </div>

                {/* Columna de información adicional */}
                <div className="space-y-6">
                  <h3 className="text-lg font-medium text-navy">
                    Información adicional
                  </h3>

                  {/* DNI/NIF/NIE con Tooltip */}
                  <div className="relative">
                    <label
                      htmlFor="dni"
                      className="text-sm font-medium text-gray-700 flex items-center"
                    >
                      DNI/NIF/NIE
                      <div className="relative ml-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-gray-400 cursor-help"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <div className="invisible group-hover:visible absolute left-6 top-1/2 -translate-y-1/2 w-96 p-4 bg-navy text-white text-xs rounded-lg z-50">
                          Aviso legal sobre protección de datos: de conformidad
                          con lo dispuesto en la normativa vigente en materia de
                          protección de datos personales, le informamos que los
                          datos proporcionados a través de este formulario serán
                          tratados por Investing Afterworks con la única
                          finalidad de gestionar su consulta o solicitud. La
                          comunicación de sus datos personales es completamente
                          voluntaria y, en ningún caso, se utilizarán con fines
                          comerciales ni se cederán a terceros sin su
                          consentimiento expreso, salvo obligación legal. Puede
                          ejercer sus derechos de acceso, rectificación,
                          supresión, oposición, limitación del tratamiento y
                          portabilidad dirigiéndose a
                          dsanchez@baboontechnologies.com. Al enviar este
                          formulario, usted consiente de manera explícita el
                          tratamiento de sus datos en los términos indicados.
                        </div>
                      </div>
                    </label>
                    <input
                      id="dni"
                      name="dni"
                      type="text"
                      required
                      className={`mt-1 block w-full border ${
                        errors.dni ? "border-red-500" : "border-gray-300"
                      } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold uppercase`}
                      value={formData.dni}
                      onChange={handleChange}
                    />
                    {errors.dni && (
                      <p className="mt-1 text-xs text-red-500">{errors.dni}</p>
                    )}
                  </div>

                  {/* Dirección desglosada */}
                  <div className="space-y-6">
                    {isGoogleLoaded && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Dirección completa
                        </label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyAw6BkQZM9JNHTOifMNa4jOok-xJv1dmmM"
                          selectProps={{
                            instanceId: addressSelectId,
                            placeholder: "Busca tu dirección...",
                            onChange: handleAddressSelect,
                            styles: customStyles,
                          }}
                          autocompletionRequest={{
                            componentRestrictions: { country: "es" },
                            types: ["address"],
                          }}
                        />
                        {formData.billing_address && (
                          <p className="mt-2 text-sm text-gray-600">
                            Dirección seleccionada: {formData.billing_address}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="professional_info"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Empresa, profesión, propósito o vocación (opcional)
                    </label>
                    <textarea
                      id="professional_info"
                      name="professional_info"
                      rows="3"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gold focus:ring-gold"
                      value={formData.professional_info}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-navy bg-gold hover:brightness-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold"
                >
                  Registrarse
                </motion.button>
              </div>
            </form>

            <div className="mt-6 text-center">
              <Link
                to="/my-investing-journey/login"
                className="text-sm text-navy hover:text-gold transition-colors duration-200"
              >
                ¿Ya tienes cuenta? Inicia sesión
              </Link>
            </div>
          </div>
        </div>
      </div>

      {showDownloadModal && (
        <DownloadModal
          title="¡Registro Completado con Éxito!"
          description="Como agradecimiento por unirte a nuestra comunidad, te regalamos esta guía que nuestros expertos han diseñado para que puedas aprender paso a paso cómo empezar a invertir."
          buttonText="Descargar Guía"
          onDownload={downloadPDF}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Register;
