import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";

const EventModal = ({ isOpen, onClose, event, onBuyTicket, onSubscribe }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleBuyTicket = async () => {
    try {
      setIsProcessing(true);
      await onBuyTicket(event);
    } catch (error) {
      console.error("Error buying ticket:", error);
      // Aquí podrías mostrar un toast/notification de error
    } finally {
      setIsProcessing(false);
    }
  };

  const renderActionButton = () => {
    if (!user) {
      return (
        <>
          <button
            onClick={handleBuyTicket}
            disabled={isProcessing || event.available_spots === 0}
            className={`w-full bg-gold text-navy font-semibold py-2 px-4 rounded-lg 
              ${
                isProcessing
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:brightness-90"
              } 
              transition-all`}
          >
            {isProcessing
              ? "Procesando..."
              : event.available_spots === 0
              ? "Agotado"
              : `Comprar Entrada - ${event.price / 100}€`}
          </button>
          <div className="text-sm text-gray-500 text-center mt-2">
            O inicia sesión para ver beneficios de suscriptor
          </div>
        </>
      );
    }

    if (event.has_access) {
      return (
        <div className="text-center">
          <div className="bg-green-100 text-green-800 px-4 py-2 rounded-lg mb-2">
            ¡Tienes acceso a este evento!
          </div>
          <p className="text-sm text-gray-600">
            Gracias a tu suscripción {user.user.subscription_type}
          </p>
        </div>
      );
    }

    return (
      <>
        <button
          onClick={handleBuyTicket}
          disabled={isProcessing || event.available_spots === 0}
          className={`w-full bg-gold text-navy font-semibold py-2 px-4 rounded-lg mb-4
            ${
              isProcessing
                ? "opacity-50 cursor-not-allowed"
                : "hover:brightness-90"
            } 
            transition-all`}
        >
          {isProcessing
            ? "Procesando..."
            : event.available_spots === 0
            ? "Agotado"
            : `Comprar Entrada - ${event.price / 100}€`}
        </button>
        <div className="text-sm text-gray-600 text-center mb-4">
          O suscríbete desde{" "}
          {event.subscription_access === "basic" ? "Basic" : "Advanced"} para
          acceder gratis
        </div>
      </>
    );
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 transition-opacity"
            onClick={onClose}
          >
            <div className="absolute inset-0 bg-navy/75 backdrop-blur-sm" />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl z-[60]"
          >
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-2xl font-libre text-navy">{event.title}</h3>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={24} />
              </button>
            </div>

            <div className="mt-4">
              <p className="text-gray-600 mb-6">{event.description}</p>

              <div className="space-y-4">
                <div className="p-4 bg-cream rounded-lg">
                  <h4 className="font-semibold text-navy mb-2">
                    Acceso al Evento
                  </h4>
                  {renderActionButton()}
                </div>

                {(!user || !event.has_access) && (
                  <div className="p-4 bg-navy text-white rounded-lg">
                    <h4 className="font-semibold mb-2">Suscripción Mensual</h4>
                    <p className="text-sm text-gray-200 mb-4">
                      Accede a todos los eventos y beneficios exclusivos
                    </p>
                    <button
                      onClick={onSubscribe}
                      className="w-full bg-gold text-navy font-semibold py-2 px-4 rounded-lg hover:brightness-90 transition-all"
                    >
                      Suscribirme desde 30€/mes
                    </button>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default EventModal;
