import axios from "axios";

//const API_URL = "http://localhost:7012/api";
const API_URL = "https://investingafterworks.com/api";

export const stripeService = {
  createCheckoutSession: async (planType) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.post(
        `${API_URL}/create-checkout-session`,
        { planType },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.data?.error === "existing_subscription") {
        return error.response.data;
      }
      console.error("Error creating checkout session:", error);
      throw error;
    }
  },

  createCustomerPortalSession: async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.post(
        `${API_URL}/customer-portal`,
        {},
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response.data.url) {
        return response.data;
      } else {
        throw new Error("No portal URL received");
      }
    } catch (error) {
      if (error.response?.status === 400) {
        // Manejar caso de no tener suscripción activa
        throw new Error("No tienes una suscripción activa");
      } else if (error.response?.status === 404) {
        // Manejar caso de usuario no encontrado
        throw new Error("Usuario no encontrado");
      }
      console.error("Error accessing customer portal:", error);
      throw new Error("Error al acceder al portal del cliente");
    }
  },

  getCurrentSubscription: async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.get(`${API_URL}/subscription`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return null; // No subscription found
      }
      console.error("Error getting subscription:", error);
      throw error;
    }
  },

  createEventCheckoutSession: async (eventId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await axios.post(
        `${API_URL}/create-event-checkout-session`,
        { eventId },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response.data.url) {
        window.location.href = response.data.url;
      }
      return response.data;
    } catch (error) {
      console.error("Error creating event checkout:", error);
      throw error;
    }
  },
};
