// frontend/src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import LandingSection from "./components/LandingSection";
import Mision from "./components/Mision";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import Pricing from "./components/Pricing";
import ReviewsSection from "./components/ReviewsSection";
import AboutUs from "./components/AboutUs";
import { authService } from "./services/authService";
import EventsSection from "./components/EventsSection";

// Componente de protección para rutas privadas
const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await authService.verifyToken();
        setIsAuthenticated(!!user);
      } catch (error) {
        navigate("/my-investing-journey/login");
      } finally {
        setIsLoading(false);
      }
    };
    checkAuth();
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-cream">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-navy"></div>
      </div>
    );
  }

  return isAuthenticated ? children : null;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <LandingSection />
              <ReviewsSection />
              <Mision />
              <EventsSection className="bg-cream" />
              <AboutUs />
              <Footer />
            </div>
          }
        />
        <Route path="/my-investing-journey/login" element={<Login />} />
        <Route path="/my-investing-journey/register" element={<Register />} />
        <Route path="/my-investing-journey/pricing" element={<Pricing />} />

        {/* Rutas protegidas */}
        <Route
          path="/my-investing-journey/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-investing-journey/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
