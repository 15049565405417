import React, { useState, useEffect } from "react";
import bussinessMeeting from "../assets/images/marta_y_david_stairs.png";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";

const Mision = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await authService.verifyToken();
        setIsAuthenticated(!!user);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  const handleStartGrowing = () => {
    if (isAuthenticated) {
      navigate("/my-investing-journey/dashboard");
    } else {
      navigate("/my-investing-journey/login");
    }
  };

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-8 sm:px-12 lg:px-16">
        <h2 className="text-3xl md:text-5xl lg:text-6xl font-libre text-navy text-center sm:text-left mb-8 leading-tight font-extrabold tracking-tight">
          <span className="block">NUESTRA</span>
          <span className="block">MISIÓN</span>
        </h2>
        <div className="flex flex-col-reverse md:flex-row items-center gap-8">
          {/* Text Column */}
          <div className="w-full md:w-1/2 text-center md:text-left space-y-4 px-4 md:px-0">
            <p className="text-base md:text-xl text-navy leading-relaxed">
              Proporcionarte el conocimiento y las herramientas necesarias para
              que puedas{" "}
              <strong>
                aplicar a tu vida el conocimiento financiero de una manera
                práctica
              </strong>{" "}
              y empieces a generar ingresos pasivos rápidamente.
            </p>
            <p className="text-base md:text-xl text-navy leading-relaxed">
              <strong>¿Vienes sin conocimientos previos?</strong> Queremos
              ayudarte a que empieces HOY tu transformación con esta{" "}
              <strong>Guía práctica gratis</strong> que hemos diseñado para que
              tomes acción y des el primer paso.
            </p>
            <p className="text-base md:text-xl text-navy leading-relaxed">
              <strong>Haz clic y empieza a construir riqueza.</strong>
            </p>

            <div className="mt-6 text-center md:text-left">
              <button
                onClick={handleStartGrowing}
                className="bg-gold text-navy font-inter font-semibold 
                  text-base md:text-lg py-2 px-4 md:py-3 md:px-6 
                  rounded-lg hover:brightness-90 transition-all"
              >
                QUIERO MI GUIA GRATIS
              </button>
            </div>
          </div>

          {/* Image Column */}
          <div className="w-full md:w-1/2">
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={bussinessMeeting}
                alt="Business People Discussing"
                className="w-full h-full object-cover rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mision;
