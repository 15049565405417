import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FaWhatsapp, FaLinkedin, FaInstagram, FaTiktok } from "react-icons/fa";
import { authService } from "../services/authService";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";

// Componente de Partners
const Partners = () => {
  const partners = [
    { name: "AndBank", image: "/partners/andbankLogo.png" },
    { name: "CNMV", image: "/partners/cnmvLogo.png" },
    { name: "Enisa", image: "/partners/enisaLogo.png" },
    { name: "My Investor", image: "/partners/myInvestorLogo.png" },
    { name: "Sapphire", image: "/partners/sapphireLogo.png" },
    { name: "Standard & Poor's", image: "/partners/standardPoorsLogo.png" },
  ];

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={30}
      slidesPerView={2}
      autoplay={{ delay: 3000 }}
      loop={true}
      breakpoints={{
        640: { slidesPerView: 3 },
        768: { slidesPerView: 4 },
        1024: { slidesPerView: 5 },
      }}
      className="partners-carousel py-8"
    >
      {partners.map((partner, index) => (
        <SwiperSlide key={index}>
          <motion.img
            src={partner.image}
            alt={partner.name}
            className="h-16 object-contain mx-auto filter grayscale hover:grayscale-0 transition-all duration-300"
            whileHover={{ scale: 1.1 }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const SocialLinks = ({ user }) => {
  const navigate = useNavigate();
  const socialLinks = [
    {
      name: "WhatsApp Básico",
      url: "https://chat.whatsapp.com/IoGrrCYYigO1O8kALev4qm",
      icon: FaWhatsapp,
      requiresPlan: "basic",
      color: "bg-[#25D366]",
    },
    {
      name: "WhatsApp Avanzado",
      url: "https://chat.whatsapp.com/FleXMEnrOfrIRX2cYhySOY",
      icon: FaWhatsapp,
      requiresPlan: "advanced",
      color: "bg-[#25D366]",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/investing-afterworks",
      icon: FaLinkedin,
      color: "bg-[#0077B5]",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/investingafterworks/",
      icon: FaInstagram,
      color: "bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#F77737]",
    },
    {
      name: "TikTok",
      url: "https://www.tiktok.com/@investingafterworks",
      icon: FaTiktok,
      color: "bg-black",
    },
  ];

  // Función auxiliar para verificar acceso
  const hasAccess = (requiredPlan) => {
    if (!requiredPlan) return true; // Si no requiere plan, acceso permitido

    const subscriptionType = user?.user?.subscription_type;

    // Si tiene plan advanced, tiene acceso a todo
    if (subscriptionType === "advanced") {
      return true;
    }

    // Si tiene plan basic, solo tiene acceso a basic
    if (subscriptionType === "basic") {
      return requiredPlan === "basic";
    }

    return false; // Si no tiene suscripción o es otro tipo
  };

  return (
    <div className="flex flex-wrap justify-center gap-8">
      <AnimatePresence>
        {socialLinks.map((link, index) => {
          const canAccess = hasAccess(link.requiresPlan);
          console.log(`Link ${link.name} access:`, canAccess); // Debug

          // Si no tiene acceso al link
          if (link.requiresPlan && !canAccess) {
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ delay: index * 0.1 }}
                className="relative group"
                onClick={() => navigate("/my-investing-journey/pricing")}
              >
                <div className="cursor-pointer bg-gray-200 p-4 rounded-full hover:bg-gray-300 transition-colors">
                  <link.icon size={28} className="text-gray-400" />
                </div>
                <div
                  className="absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 
                  transition-all duration-200 ease-in-out pointer-events-none
                  bottom-full left-1/2 transform -translate-x-1/2 mb-2
                  bg-navy/90 backdrop-blur-sm text-white text-xs py-2 px-3 rounded-lg whitespace-nowrap
                  before:content-[''] before:absolute before:top-full before:left-1/2 before:-translate-x-1/2
                  before:border-4 before:border-transparent before:border-t-navy/90"
                >
                  {user?.user?.subscription_type === "basic"
                    ? "Mejora tu plan para acceder"
                    : "Suscríbete para unirte"}
                </div>
              </motion.div>
            );
          }

          // Si tiene acceso, mostrar el link activo
          return (
            <motion.a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ delay: index * 0.1 }}
              className={`${link.color} p-4 rounded-full hover:scale-110 transition-transform`}
            >
              <link.icon size={28} className="text-white" />
            </motion.a>
          );
        })}
      </AnimatePresence>
    </div>
  );
};

const Dashboard = ({ requiredPlan }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await authService.getCurrentUser();
        setUser(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, []);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="min-h-screen bg-cream">
      <Navbar />

      {/* Sección 1: Presentación */}
      <section className="py-24 px-6 md:px-8 lg:px-12">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center mb-16"
          >
            <motion.h1
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.8,
                ease: "easeOut",
              }}
              className="text-5xl md:text-7xl font-libre text-navy mb-8"
            >
              ¡Bienvenidos a{" "}
              <span className="relative inline-block">
                <motion.span
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                  className="text-gold"
                >
                  My Investing Journey!
                </motion.span>
                <motion.div
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{ delay: 1, duration: 0.8 }}
                  className="absolute -bottom-4 left-0 w-full h-1 bg-gold"
                />
              </span>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.2, duration: 0.8 }}
              className="text-xl md:text-2xl text-gray-700 max-w-4xl mx-auto leading-relaxed mt-12"
            >
              En el mundo actual, aprender a manejar el dinero no es solo una
              habilidad,{" "}
              <span className="font-semibold text-navy">
                es una superpotencia
              </span>
              , un superpoder. En My Investing Journey, queremos acompañarte en
              un camino transformador, lleno de herramientas y conocimientos
              prácticos que te permitirán tomar el control de tu futuro
              financiero y pasar de sobrevivir a supervivir.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1.4, duration: 0.8 }}
              className="mt-12 text-xl md:text-2xl font-libre text-navy"
            >
              <h2 className="mb-6">
                ¿Por qué esta experiencia puede cambiar tu vida?
              </h2>
              <p className="text-lg text-gray-700 max-w-4xl mx-auto">
                La educación financiera es el puente entre donde estás ahora y
                donde quieres estar. No importa si nunca has invertido un euro o
                si el mundo financiero te resulta intimidante; esta plataforma
                está diseñada para personas como tú, que desean construir una
                base sólida para alcanzar sus metas. No necesitas ser un
                experto, solo necesitas dar el primer paso.
              </p>
            </motion.div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.6, duration: 0.8 }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl font-libre text-navy mb-8">
              ¿Qué descubrirás en el Nivel Básico?
            </h2>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.8, duration: 0.8 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          >
            {[
              {
                title: "Tu plan financiero",
                description:
                  "Aprende a organizar tus ingresos y gastos, identificar metas claras y construir un camino hacia la libertad financiera.",
              },
              {
                title: "Estrategias de ahorro inteligentes",
                description:
                  "Descubre cómo optimizar cada euro sin sacrificar tu estilo de vida, creando hábitos financieros sostenibles.",
              },
              {
                title: "Tu mapa del inversor",
                description:
                  "Identifica tu perfil como inversor, conoce tus opciones y construye una hoja de ruta personalizada para alcanzar tus objetivos.",
              },
              {
                title: "Tecnología que impulsa tus finanzas",
                description:
                  "Aprende a aprovechar las mejores apps de trading y neobancos para gestionar tus inversiones de manera sencilla y eficiente.",
              },
              {
                title: "Psicología del inversor",
                description:
                  "Entiende cómo las emociones y los sesgos pueden influir en tus decisiones y aprende a tomar el control en los momentos clave.",
              },
              {
                title: "Conceptos esenciales",
                description:
                  "Riesgo, rentabilidad, interés compuesto y diversificación serán explicados de forma clara y accesible para que puedas aplicarlos desde el inicio.",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.8 + index * 0.1 }}
                className="bg-white/50 backdrop-blur-sm p-8 rounded-xl shadow-sm"
              >
                <h3 className="text-xl font-libre text-navy mb-4">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Sección 2: Partners */}
      <section className="py-16 bg-white/50">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl md:text-4xl font-libre text-navy mb-6 relative inline-block">
              ¿Por qué emprender My Investing Journey?
              <motion.div
                className="absolute -bottom-2 left-0 w-full h-1 bg-gold"
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ delay: 0.5, duration: 0.8 }}
              />
            </h2>
            <p className="text-lg text-gray-600">
              Colaboramos con las mejores empresas del sector financiero
            </p>
          </motion.div>
          <Partners />
        </div>
      </section>

      {/* Sección 4: Comunidad */}
      <section className="py-16 bg-navy text-white">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <h2 className="text-3xl md:text-4xl font-libre mb-6 relative inline-block">
              Únete a nuestra <span className="text-gold">Comunidad</span>
            </h2>
            <p className="text-xl mb-12 max-w-3xl mx-auto">
              Conecta con inversores, expertos y entusiastas de las finanzas.
              Comparte experiencias, aprende de otros y crece junto a una
              comunidad que comparte tus mismos objetivos.
            </p>
            <SocialLinks user={user} />
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
