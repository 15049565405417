import React from "react";
import { motion } from "framer-motion";
import { X } from "lucide-react";

export const DownloadModal = ({
  title,
  description,
  buttonText,
  onDownload,
  onClose,
}) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4">
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      className="w-full max-w-4xl bg-white rounded-2xl shadow-2xl p-12 relative border border-nude text-center space-y-6"
    >
      <button
        onClick={onClose}
        className="absolute -top-2 -right-2 bg-nude rounded-full p-2 hover:bg-gold transition-colors"
      >
        <X className="text-navy" size={24} />
      </button>

      <motion.h2
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="text-4xl font-bold text-navy font-libre"
      >
        {title}
      </motion.h2>

      <p className="text-xl text-dark-beige font-inter">{description}</p>

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onDownload}
        className="bg-gold text-navy px-8 py-3 rounded-full shadow-lg hover:brightness-90 font-inter font-semibold transition-all"
      >
        {buttonText}
      </motion.button>
    </motion.div>
  </div>
);
