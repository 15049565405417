import React, { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import BonusModal from "./common/BonusModal";
import PricingPlans from "./PricingPlans";
import { Check } from "lucide-react";
import { authService } from "../services/authService";

const PlanFeature = ({ title, description, bulletPoints = [] }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="bg-white/50 backdrop-blur-sm rounded-lg p-6 shadow-sm hover:shadow-lg transition-all duration-300"
    whileHover={{ scale: 1.01 }}
  >
    <h3 className="text-xl font-libre text-navy mb-4 font-bold">{title}</h3>
    <p className="text-gray-600 font-inter leading-relaxed mb-4">
      {description}
    </p>
    {bulletPoints.length > 0 && (
      <ul className="space-y-3">
        {bulletPoints.map((point, index) => (
          <motion.li
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            className="flex items-start space-x-3"
          >
            <Check className="text-gold flex-shrink-0 mt-1" size={18} />
            <span className="text-gray-600 font-inter">{point}</span>
          </motion.li>
        ))}
      </ul>
    )}
  </motion.div>
);

const Pricing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showBonusModal, setShowBonusModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (searchParams.get("gift") === "true") {
      setShowBonusModal(true);
      setSearchParams(new URLSearchParams());
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await authService.verifyToken();
        setIsAuthenticated(!!user);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  const handleBonusDownload = async () => {
    try {
      const response = await fetch("/files/iaw_guide.pdf");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download =
        "Guía Básica sobre Cómo Invertir Bien - Investing Afterworks";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowBonusModal(false);
    } catch (error) {
      console.error("Error al descargar el bonus:", error);
      setShowBonusModal(false);
    }
  };

  const scrollToPlans = () => {
    const plansSection = document.querySelector("#pricing-plans");
    plansSection.scrollIntoView({ behavior: "smooth" });
  };

  const basicPlanFeatures = [
    {
      title: "No conozco a expertos en inversiones ni finanzas",
      description:
        "En Investing Afterworks reunimos a los mejores expertos nacionales e internacionales y los ponemos a tu disposición sin que tengas que buscarlos tu, ahorrándote tiempo de búsquedas a 'puerta fría' y dinero en asesoramientos privados. Podrás contactar tanto en persona como de manera online con expertos de diferentes ámbitos de una manera fluida y cercana.",
      bulletPoints: [
        "Contacto directo con profesionales expertos en fondos de inversiones, criptomonedas y bitcoin, sector inmobiliario, acciones, etc.",
        "Consultas mediante llamada, whatsapp o email con nuestros expertos.",
        "Networking a través de nuestros mastermind, eventos y fiestas.",
      ],
    },
    {
      title: "Me cuesta confiar en personas que se dedican a estos temas",
      description:
        "En el mundo de las inversiones, desgraciadamente, algunos profesionales aprovechan el desconocimiento de las personas para beneficiarse de ellas, provocándole serios problemas o rentabilidades mucho inferiores a las que podría optar. A veces, creer en los consejos de un familiar o amigo no resulta siendo la mejor decisión y acarrea serias consecuencias.",
      bulletPoints: [
        "Asesorías 1 a 1 con nuestros expertos de confianza.",
        "Foro online para preguntar y debatir temas de interés moderados por nuestros profesionales",
        "Acceso a charlas con profesionales de renombre con alta reputación",
      ],
    },
    {
      title:
        "Quiero aprender sobre inversiones y no encuentro una formación que me satisfaga",
      description:
        "Aquí te demostramos cómo puedes aprender las claves para invertir estratégicamente sin necesidad de dedicarle ni muchas horas ni mucho esfuerzo y sin tener que pagar cursos caros o másters en escuelas de negocios de 30.000€ que no te garantizan resultados ni una aplicación práctica y realista.",
      bulletPoints: [
        "Sesiones grupales en formato Afterwork con plazas limitadas",
        "Desarrolla tu propio plan financiero supervisado por expertos",
        "Elección de temas de mastermind a través de tu voto",
        "Videos explicativos y grabaciones de las sesiones presenciales online",
        "Simulador de inversiones y buscador de oportunidades",
      ],
    },
    {
      title: "Quiero ahorrar más y de manera más inteligente",
      description:
        "Te ayudaremos a desarrollar estrategias de ahorro que te permitirán tener un mejor control de tus cuentas y reducir tus gastos para aumentar tu capital. Y luego, ¡haremos que esos ahorros te den dinero gratis!",
      bulletPoints: [
        "Diseño y elaboración de tu plan financiero personalizado",
        "Acompañamiento y seguimiento mensual de tu progreso",
        "Cambia tu percepción sobre el dinero con sesiones de psicología",
        "Descubre tu pack ideal de productos financieros",
      ],
    },
    {
      title:
        "Quiero invertir inteligentemente y perder el miedo a perder dinero",
      description:
        "El miedo a lo desconocido impera en todos los que desconocen cómo funcionan las finanzas. Te enseñaremos cómo entender cuáles son los mejores productos financieros para ti y cómo hacer movimientos inteligentes.",
      bulletPoints: [
        "Entiende en qué momento de pirámide financiera estás",
        "Empodérate sesión tras sesión",
        "Domina el conocimiento y no vuelvas a depender de nadie",
      ],
    },
  ];

  const basicPlanBenefits = [
    "Mínimo 1 evento al mes (precio de entrada incluido + 2 bebidas)",
    "1 mastermind del mismo nivel",
    "Masterminds de nivel superior con 50% de descuento",
    "Tu portal online My investing journey",
    "Grupo de whatsapp de la comunidad con asesores expertos",
    "Contacto personalizado con nuestros expertos",
    "Asesoramiento personalizado 1 a 1",
    "Networking con expertos y empresarios",
    "Comunidad de profesionales",
  ];

  const advancedPlanFeatures = [
    {
      title: "No conozco a expertos en temas específicos",
      description:
        "En Investing Afterworks te traemos a los referentes TOP nacionales e internacionales de cada especialidad y los ponemos a tu disposición sin que tengas que pasar horas y horas comparando productos, intentando aprender por tu cuenta o viendo vídeos de Youtubers con fines de ética dudosa. Ahorra tiempo y evita disgustos: ahora tienes la oportunidad de resolver todas tus dudas y debatir con expertos que ameritan sólidas trayectorias profesionales y compartir experiencias y conocimiento con otras personas con tus mismos intereses tanto en persona como de manera online.",
      bulletPoints: [
        "Contacto directo con expertos en fondos de inversiones, criptomonedas y bitcoin, sector inmobiliario, acciones, etc.",
        "Consultas mediante llamada, whatsapp o email con nuestros profesionales.",
        "Networking a través de nuestros mastermind, eventos y fiestas.",
      ],
    },
    {
      title: "Me cuesta confiar en personas que se dedican a estos temas",
      description:
        "En el mundo de las inversiones, desgraciadamente, algunas personas aprovechan el desconocimiento para beneficiarse, provocando serios problemas en las personas que siguen sus consejos o rentabilidades mucho inferiores a las que podrían optar. En Investing Afterworks, podrás saber de primera mano cuáles són la mejores oportunidades y cuáles conllevan un alto riesgo y aprenderás a discernir las ofertas envenenadas de las que sí que valen la pena, intercambiando información con nuestros expertos y entre los miembros de la comunidad. Nuestros valores más importantes son la honestidad y la transparencia, y nuestro objetivo es empoderarte para que puedas sacar tus propias conclusiones desde el poder del conocimiento.",
      bulletPoints: [
        "Asesorías 1 a 1 con nuestros expertos de confianza.",
        "Foro online para preguntar y debatir temas de interés moderados por nuestros profesionales.",
        "Acceso a charlas con profesionales de renombre con alta reputación y trayectorias profesionales impecables sobre cada sector",
      ],
    },
    {
      title: "Quiero estar al día de las últimas oportunidades de inversión",
      description:
        "Podrás acceder a nuestra newsletter sistemática y nuestro grupo de Whatsapp Premium donde podrás saber al momento las mejores oportunidades de inversión filtradas por nuestro equipo de expertos y la tecnología se Systematic Value investing que analiza todas las empresas del mercado diariamente. ¡Así de simple! Sin que tengas que estar pendiente ni perder tiempo analizando aplicaciones de trading.",
      bulletPoints: [
        "Acceso al grupo Premium de Whatsapp con alertas de oportunidades diarias.",
        "Foro online en My Investing Journey donde podrás debatir cualquier tema con expertos y otras personas dedicadas al análisis de empresas.",
      ],
    },
    {
      title:
        "Quiero invertir inteligentemente y perder el miedo a perder dinero",
      description:
        "Si has vivido o visto situaciones de alto riesgo y pérdida de dinero, a través de nuestros eventos de nivel avanzado y mastermind aprenderás a valorar el riesgo y evitar volver a perder dinero, aprendiendo de los mejores expertos y personas que han pasado por experiencias parecidas a la tuya. Compartir los aprendizajes y aprender de los mayores expertos te permitirá tener la capacidad de tomar las mejores decisiones.",
      bulletPoints: [
        "Masterminds para entender profundamente las mejores estrategias de inversión y valoración de riesgo siempre actualizado.",
        "Sesiones de nivel avanzado para profundizar en aquellos temas que más te preocupen.",
      ],
    },
    {
      title: "Quiero conocer a personas que compartan mis inquietudes",
      description:
        "En Investing Afterworks contamos con una red de profesionales de todos los sectores con inquietudes y ambición. Si tu también quieres pasar al siguiente nivel, ésta es la oportunidad de crecer personal y profesionalmente en un ambiente inspirador, rodeado de personas como tú, mientras mejoras tus inversiones y multiplicas tu riqueza. Y encima, divirtiéndote. ¡Aquí ganamos x3!",
      bulletPoints: [
        "Accede a contactos dispuestos a colaborar y establecer sinergias profesionales tanto en los eventos presenciales como en los foros y grupos de whatsapp.",
        "Aprende de tus compañerxs de viaje y enriquecete a todos los niveles: financiero, personal y profesional.",
        "Aprovecha la oportunidad de contactar con grandes referentes en el mundo de los negocios y de las inversiones con nuestro acceso VIP a las cenas del club Élite.",
      ],
    },
  ];

  const advancedPlanBenefits = [
    "2 eventos Afterwork al mes (precio de entrada incluido + 2 bebidas)",
    "1 Mastermind Genius al mes con expertos TOP",
    "Tu portal online My investing journey",
    "Grupo de Whatsapp Premium para estar a la última sobre oportunidades de inversión",
    "Contacto personalizado con nuestros expertos para consultas por email, whatsapp o llamada",
    "Asesoramiento personalizado 1 a 1 con nuestros profesionales",
    "Networking con expertos, inversores y empresarios de alto nivel",
    "Comunidad de profesionales que comparten tus mismas inquietudes + foros de debate online",
    "2 cenas Club Élite con los empresarios e inversionistas más influyentes y exitosos",
  ];

  return (
    <div className="min-h-screen bg-cream relative">
      {/* Botón flotante para ir a planes */}
      <motion.button
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        className="fixed top-4 right-4 z-50 bg-gold text-navy font-inter font-semibold 
        text-base sm:text-lg px-3 sm:px-6 py-2 sm:py-3 
        rounded-lg hover:scale-105 active:scale-95 transition-all 
        flex items-center justify-center whitespace-nowrap"
        onClick={scrollToPlans}
      >
        Ver Planes
      </motion.button>

      {/* Botón flotante para ir al dashboard */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="fixed top-4 left-4 z-50"
      >
        {isAuthenticated ? (
          <Link
            to="/my-investing-journey/dashboard"
            className="bg-gold text-navy font-inter font-semibold 
            text-base sm:text-lg px-3 sm:px-6 py-2 sm:py-3 
            rounded-lg hover:scale-105 active:scale-95 transition-all 
            flex items-center justify-center whitespace-nowrap"
          >
            Mi Portal
          </Link>
        ) : (
          <Link
            to="/my-investing-journey/login"
            className="bg-gold text-navy font-inter font-semibold 
            text-base sm:text-lg px-3 sm:px-6 py-2 sm:py-3 
            rounded-lg hover:scale-105 active:scale-95 transition-all 
            flex items-center justify-center whitespace-nowrap"
          >
            Accede
          </Link>
        )}
      </motion.div>

      {showBonusModal && (
        <BonusModal
          onClose={() => setShowBonusModal(false)}
          onDownload={handleBonusDownload}
        />
      )}

      <div className="max-w-7xl mx-auto py-16">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-6xl font-libre text-navy mb-6 relative">
            <span className="relative inline-block">
              ¿Cuáles son tus problemas?
              <motion.div
                className="absolute -bottom-2 left-0 w-full h-1 bg-gold"
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ delay: 0.5, duration: 0.8 }}
              />
            </span>
          </h1>
        </motion.div>

        {/* Grid de problemas y soluciones */}
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <p className="text-xl text-gray-600 font-inter">
              En el plan básico, nos comprometemos a resolver cada uno de ellos:
            </p>
          </div>

          <div className="grid gap-8 mb-20">
            {/* Primera fila: 3 elementos */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {basicPlanFeatures.slice(0, 3).map((feature, index) => (
                <PlanFeature key={index} {...feature} />
              ))}
            </div>
            {/* Segunda fila: 2 elementos centrados */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:w-2/3 mx-auto">
              {basicPlanFeatures.slice(3).map((feature, index) => (
                <PlanFeature key={index} {...feature} />
              ))}
            </div>
          </div>
        </div>

        {/* Beneficios del Plan Básico */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="bg-white/50 backdrop-blur-sm rounded-xl shadow-xl p-8 mb-20 max-w-6xl mx-auto"
        >
          <h3 className="text-2xl font-libre text-navy mb-6">
            El Plan básico te incluye:
          </h3>
          <ul className="grid md:grid-cols-2 gap-4 mb-8">
            {basicPlanBenefits.map((benefit, index) => (
              <li key={index} className="flex items-center space-x-3">
                <Check className="text-gold flex-shrink-0" size={18} />
                <span className="text-gray-600 font-inter">{benefit}</span>
              </li>
            ))}
          </ul>

          <div className="text-center">
            <p className="text-2xl font-libre text-navy mb-4">
              ¡Todo esto por solamente 30€ al mes!
            </p>
            <p className="text-gray-600 font-inter">
              Prueba nuestras sesiones y nuestra plataforma online. Cancela en
              cualquier momento antes del día 15 de cada mes.
            </p>
          </div>
        </motion.div>

        {/* Plan Avanzado */}
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-libre text-navy mb-6 relative inline-block">
              Plan Avanzado
              <motion.div
                className="absolute -bottom-2 left-0 w-full h-1 bg-gold"
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ delay: 0.5, duration: 0.8 }}
              />
            </h2>
            <p className="text-xl text-gray-600 font-inter mt-4">
              ¿Estás list@ para pasar al siguiente nivel?
            </p>
          </div>

          <div className="grid gap-8 mb-20">
            {/* Primera fila: 3 elementos */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {advancedPlanFeatures.slice(0, 3).map((feature, index) => (
                <PlanFeature key={index} {...feature} />
              ))}
            </div>
            {/* Segunda fila: 2 elementos centrados */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:w-2/3 mx-auto">
              {advancedPlanFeatures.slice(3).map((feature, index) => (
                <PlanFeature key={index} {...feature} />
              ))}
            </div>
          </div>

          {/* Beneficios del Plan Avanzado */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="bg-white/50 backdrop-blur-sm rounded-xl shadow-xl p-8 mb-20 max-w-6xl mx-auto"
          >
            <h3 className="text-2xl font-libre text-navy mb-6">
              El Plan Avanzado te permite:
            </h3>
            <ul className="grid md:grid-cols-2 gap-4 mb-8">
              {advancedPlanBenefits.map((benefit, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <Check className="text-gold flex-shrink-0" size={18} />
                  <span className="text-gray-600 font-inter">{benefit}</span>
                </li>
              ))}
            </ul>

            <div className="text-center">
              <p className="text-2xl font-libre text-navy mb-4">
                ¡Todo esto por solamente 50€ al mes!
              </p>
              <p className="text-gray-600 font-inter">
                ¡Así es imposible equivocarse! Prueba nuestras sesiones y
                nuestra plataforma online y cuéntanos tu experiencia con
                nosotros. Si no te gusta, puedes cancelar tu suscripción en
                cualquier momento antes del día 15 de cada mes (aunque estamos
                convencidos que te encantará formar parte de esta aventura).
                Como ves, ¡nada que perder y mucho que ganar!
              </p>
            </div>
          </motion.div>
        </div>

        {/* Planes de Precios */}
        <div
          id="pricing-plans"
          className="backdrop-blur-sm rounded-xl p-8 max-w-6xl mx-auto"
        >
          <PricingPlans />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
