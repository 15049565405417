// frontend/src/services/authService.js
import axios from "axios";

//const API_URL = "http://localhost:7012/api";
const API_URL = "https://investingafterworks.com/api";

export const authService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password,
      });

      console.log("Login response:", response.data); // Debug

      if (response.data && response.data.token) {
        const userData = {
          token: response.data.token,
          user: response.data.user, // Asumiendo que el backend envía los datos del usuario
        };
        localStorage.setItem("user", JSON.stringify(userData));
        return userData;
      }
      throw new Error("No token received");
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  },

  register: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/auth/register`, userData);

      // Si el registro es exitoso, guardamos el token y los datos del usuario
      if (response.data && response.data.token) {
        const userData = {
          token: response.data.token,
          user: response.data.user,
        };
        localStorage.setItem("user", JSON.stringify(userData));
        return userData;
      }
      throw new Error("No token received after registration");
    } catch (error) {
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem("user");
  },

  getCurrentUser: () => {
    try {
      const userStr = localStorage.getItem("user");
      if (!userStr) {
        console.log("No user found in localStorage");
        return null;
      }
      return JSON.parse(userStr);
    } catch (error) {
      console.error("Error parsing user data:", error);
      return null;
    }
  },

  verifyToken: async () => {
    const user = authService.getCurrentUser();

    if (user?.token) {
      try {
        const response = await axios.get(`${API_URL}/auth/verify`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });

        // Asegurarse de que devolvemos todos los campos necesarios
        return {
          ...response.data,
          subscription_status: response.data.subscription_status,
          dni: response.data.dni,
          whatsapp: response.data.whatsapp,
        };
      } catch (error) {
        authService.logout();
        return null;
      }
    }
    return null;
  },

  isAuthenticated: () => {
    const user = authService.getCurrentUser();
    return !!user?.token;
  },
};
