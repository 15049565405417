// frontend/src/components/Login.js
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { authService } from "../services/authService";
import { motion } from "framer-motion";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await authService.login(email, password);
      console.log("Login successful:", userData); // Debug

      if (!userData || !userData.token) {
        console.error("No token received in userData");
        setError("Error de autenticación");
        return;
      }

      // Redirigir basado en el estado de suscripción del usuario
      if (userData.user.subscription_status === "active") {
        navigate("/my-investing-journey/dashboard");
      } else {
        navigate("/my-investing-journey/pricing");
      }
    } catch (err) {
      console.error("Login error:", err);
      setError(err.response?.data?.error || "Credenciales inválidas");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cream py-12 px-4 sm:px-6 lg:px-8 relative">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="absolute top-4 left-4 z-50"
      >
        <Link
          to="/"
          className="bg-gold text-navy font-inter font-semibold 
          text-base sm:text-lg px-3 sm:px-6 py-2 sm:py-3 
          rounded-lg hover:scale-105 active:scale-95 transition-all 
          flex items-center justify-center whitespace-nowrap"
        >
          Volver a Inicio
        </Link>
      </motion.div>

      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow-lg">
        <div>
          <h2 className="text-3xl md:text-4xl font-libre text-navy text-center mb-2 leading-tight font-extrabold tracking-tight">
            Accede a tu cuenta
          </h2>
          <p className="mt-2 text-center text-lg text-gray-600 font-inter">
            Y comienza tu viaje de inversión
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative font-inter"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <input
                type="email"
                required
                className="appearance-none rounded-t-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gold focus:border-gold focus:z-10 font-inter"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                required
                className="appearance-none rounded-b-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gold focus:border-gold focus:z-10 font-inter"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-inter font-semibold rounded-lg text-navy bg-gold hover:brightness-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold transition-all"
            >
              Iniciar Sesión
            </button>
          </div>
        </form>
        <div className="mt-4 p-4  rounded-lg">
          <p className="text-navy text-center font-inter italic">
            "Esta cuenta te abre las puertas a las finanzas del futuro.
            <span className="block mt-1 font-semibold not-italic">
              Aprende, invierte y construye la libertad financiera que mereces."
            </span>
          </p>
        </div>
        <div className="text-center font-inter">
          <Link
            to="/my-investing-journey/register"
            className="text-navy hover:text-gold transition-colors"
          >
            ¿No tienes cuenta? Regístrate
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
