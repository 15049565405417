import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Linkedin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";

const LinkedInCard = ({ image, name, title, linkedinUrl }) => {
  return (
    <motion.div
      className="relative w-80 bg-white rounded-xl shadow-xl overflow-hidden"
      whileHover={{ scale: 1.02 }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
    >
      <div className="w-full h-64">
        <img src={image} alt={name} className="w-full h-full object-cover" />
      </div>

      <div className="p-4 text-center">
        <h3 className="text-xl font-libre text-navy font-bold mb-1 tracking-tight">
          {name}
        </h3>
        <p className="text-sm font-inter text-gray-600 mb-3 px-2 line-clamp-2 leading-snug tracking-tight">
          {title}
        </p>
        <a
          href={linkedinUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center w-9 h-9 bg-navy rounded-full hover:bg-gold transition-colors duration-300"
        >
          <Linkedin className="text-white" size={18} />
        </a>
      </div>
    </motion.div>
  );
};

const BioSection = ({ member, imageOnRight = true }) => {
  const formatText = (paragraph) => {
    // Para Marta
    if (member.name.includes("Marta")) {
      if (paragraph.startsWith("✨ Curiosidad y pasión por crear:")) {
        return {
          isTitle: true,
          text: "Curiosidad y pasión por crear:",
          showIcon: "✨",
        };
      }
      if (paragraph.startsWith("💡 Nuestra misión:")) {
        return {
          isTitle: true,
          text: "Nuestra misión:",
          showIcon: "💡",
        };
      }
      if (paragraph.startsWith("🌍 El mundo está cambiando:")) {
        return {
          isTitle: true,
          text: "El mundo está cambiando:",
          showIcon: "🌍",
        };
      }
      if (paragraph.startsWith("✔️")) {
        return {
          isBullet: true,
          text: paragraph.slice(2),
        };
      }
    }

    // Para David
    if (member.name.includes("David")) {
      if (paragraph.startsWith("📈 Lo básico:")) {
        return {
          isTitle: true,
          text: "Lo básico:",
          showIcon: "📈",
        };
      }
      if (paragraph.startsWith("📊 Lo avanzado:")) {
        return {
          isTitle: true,
          text: "Lo avanzado:",
          showIcon: "📊",
        };
      }
      if (paragraph.startsWith("•")) {
        return {
          isBullet: true,
          text: paragraph.slice(1),
        };
      }
    }

    // Para párrafos normales
    return {
      isNormal: true,
      text: paragraph,
    };
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.4 }}
      className="flex flex-col md:flex-row items-start gap-6 py-8"
    >
      <div
        className={`flex-1 ${imageOnRight ? "order-1" : "order-1 md:order-2"}`}
      >
        <div className="space-y-2 max-w-2xl mx-auto">
          <motion.h3
            className="text-3xl font-libre text-navy mb-4 relative inline-block"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4 }}
          >
            {member.name}
            <motion.div
              className="absolute -bottom-1 left-0 w-full h-1 bg-gold"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ delay: 0.2, duration: 0.4 }}
            />
          </motion.h3>
          <div className="prose prose-sm max-w-none text-gray-600 font-inter space-y-1">
            {member.bio.split("\n").map((paragraph, index) => {
              if (!paragraph.trim()) return null;

              const formattedText = formatText(paragraph.trim());

              if (formattedText.isTitle) {
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.05, duration: 0.4 }}
                    className="mt-3 mb-1"
                  >
                    <h4 className="text-lg font-libre text-navy font-bold flex items-center gap-1">
                      <span className="text-xl">{formattedText.showIcon}</span>
                      {formattedText.text}
                    </h4>
                  </motion.div>
                );
              }

              if (formattedText.isBullet) {
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.05, duration: 0.4 }}
                    className="flex items-center space-x-1 pl-3"
                  >
                    <div className="w-1 h-1 rounded-full bg-gold flex-shrink-0" />
                    <p className="mt-0 mb-0.5 leading-snug tracking-tight">
                      {formattedText.text}
                    </p>
                  </motion.div>
                );
              }

              return (
                <motion.p
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.05, duration: 0.4 }}
                  className="leading-snug tracking-tight mb-1"
                >
                  {formattedText.text}
                </motion.p>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className={`md:w-1/3 ${
          imageOnRight ? "order-2" : "order-2 md:order-1"
        }`}
      >
        <LinkedInCard {...member} />
      </div>
    </motion.div>
  );
};

const AboutUs = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await authService.verifyToken();
        setIsAuthenticated(!!user);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  const handleStartGrowing = () => {
    if (isAuthenticated) {
      navigate("/my-investing-journey/dashboard");
    } else {
      navigate("/my-investing-journey/login");
    }
  };

  const teamMembers = [
    {
      image: "/david_presentation.png",
      name: "David Sanchez Molina",
      title:
        "Co-Founder & CEO in Baboon Technologies | Professor in EADA Business School | Financial Advisor",
      linkedinUrl: "https://www.linkedin.com/in/davidmirrorauthor/",
      bio: `🤝 Soy David Sánchez Molina, cofundador de Systematic Value Investing.
    
    En nuestros Investing Afterworks charlamos sobre bolsa, inversiones y educación financiera. Entre copas y un ambiente informal, abordamos:
    
    📈 Lo básico:
    • Crear un plan desde 0 para inversiones personales
    • Oportunidades y riesgos de inversión este año
    • Neobancos y brokers online recomendados
    • Control de portfolio de inversión
    
    📊 Lo avanzado:
    • Valoración de activos
    • Gestión de riesgo en portfolios
    • Análisis de diferentes tipos de activos
    • Inversión según ciclo económico y macroeconomía
    
👍 Me apasiona organizar estas sesiones porque creo que saber de inversiones es como saber de nutrición: A corto plazo, no pasa nada si no sabes. A largo plazo, puede ser una catástrofe.

📚 Además de asesorar al fondo del SVI - US Markets, asesoro financieramente a particulares y empresas, y enseño en el Máster de Finanzas y FinTech en EADA Business School.

🎓 De formación soy Ingeniero Aeroespacial y Economista, comprometido con la divulgación financiera.`,
    },
    {
      image: "/marta_presentation.png",
      name: "Marta Vara Tarin",
      title:
        "Business and Marketing strategy | Co-founder of Investing Afterworks | Founder of Utopia",
      linkedinUrl: "https://www.linkedin.com/in/martavaratarin/",
      bio: `¡Hola! Soy Marta Vara, encantada de conocerte. 😊
    
    ✨ Curiosidad y pasión por crear:
    Si algo define mi camino hasta aquí, es la curiosidad (y unas ganas imparables de meterme en mil proyectos a la vez). Cofundé Investing Afterworks porque creo firmemente que las finanzas no deberían ser un terreno exclusivo para expertos. Mi objetivo es hacerlas accesibles, prácticas y sobre todo, ¡divertidas! 🎉
    
    💡 Nuestra misión:
    Democratizar el conocimiento financiero para que todo el mundo tenga las herramientas suficientes para navegar por cualquier contexto económico y preservar y aumentar su patrimonio de la manera más rentable posible.
    
    🌍 El mundo está cambiando:
    Ahora más que nunca, la tecnología avanza a una velocidad vertiginosa. Criptomonedas, neobancos, nuevos productos financieros y sistemas económicos están revolucionando las reglas del juego.
    
    ✔️ Tomar decisiones inteligentes sobre nuestro dinero.
    ✔️ Aprovechar las oportunidades del futuro.
    ✔️ Protegernos de aquello que nos hace perder poder económico.
    
    🔗 Mi "superpoder" es crear conexiones entre personas, ideas y sectores. He pasado por mundos tan distintos como:
    🎭 La producción de eventos con Utopia
    🎼 El mundo de las orquestas de música clásica como Oboísta profesional
    👩‍⚕️ Atención sanitaria como Enfermera, también en Pediatria
    📊 Dirección y Gobernanza clínica en Salud
    🔬 La investigación en Salud Pública
    🎙️ El podcast Epicgenetics`,
    },
  ];

  return (
    <div className="bg-cream py-12">
      <div className="max-w-7xl mx-auto px-8 sm:px-12 lg:px-16">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.4 }}
          className="text-center mb-8"
        >
          <h2 className="text-4xl md:text-5xl font-libre text-navy mb-4 relative inline-block">
            Conócenos
            <motion.div
              className="absolute -bottom-2 left-0 w-full h-1 bg-gold"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ delay: 0.2, duration: 0.4 }}
            />
          </h2>
        </motion.div>

        {/* Biografías con tarjetas alternadas */}
        <div className="space-y-6">
          <BioSection member={teamMembers[0]} imageOnRight={true} />
          <div className="w-full h-px bg-navy/10" />
          <BioSection member={teamMembers[1]} imageOnRight={false} />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.4 }}
          className="text-center mt-8"
        >
          <button
            onClick={handleStartGrowing}
            className="bg-gold text-navy font-inter font-semibold 
              text-base md:text-lg py-2 px-6 
              rounded-lg hover:brightness-90 transition-all duration-200
              hover:scale-105 active:scale-95"
          >
            QUIERO SABER MÁS
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUs;
